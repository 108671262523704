import * as React from 'react';
import classNames from 'classnames';
import { ImageProps } from '../Image.types';
import hoverBoxUtils from '../../MediaContainers/HoverBox/utils';
import styles from './style/Image.scss';

const Image: React.FC<ImageProps> = props => {
  const {
    id,
    containerId,
    uri,
    alt,
    name,
    role,
    width,
    height,
    displayMode,
    devicePixelRatio,
    quality,
    alignType,
    hasBgScrollEffect,
    bgEffectName = '',
    focalPoint,
    upscaleMethod,
    className = '',
    crop,
    isZoomed,
    imageStyles = {},
    onLoad = () => {},
    onError = () => {},
    getPlaceholder,
    containerWidth,
    containerHeight,
    isInFirstFold,
    lazyLoadImgExperimentOpen,
    fetchPriorityExperimentOpen,
  } = props;

  // fix containerId and id to support hoverBox component
  const fixedContainerId = hoverBoxUtils.getDefaultId(containerId);
  const fixedId = hoverBoxUtils.getDefaultId(id)!;
  let hasSsrSrc = '';

  const imageInfo = {
    containerId: fixedContainerId,
    ...(alignType && { alignType }),
    displayMode,
    imageData: {
      width,
      height,
      uri,
      name,
      displayMode,
      ...(quality && { quality }),
      ...(devicePixelRatio && { devicePixelRatio }),
      ...(focalPoint && { focalPoint }),
      ...(crop && { crop }),
      ...(upscaleMethod && { upscaleMethod }),
    },
  };

  const imagePlaceholderData = React.useRef<any>(null);

  if (!imagePlaceholderData.current) {
    if (getPlaceholder) {
      hasSsrSrc = isInFirstFold ? '' : 'true';

      const options = {
        upscaleMethod,
        ...(quality ? quality : {}),
        shouldLoadHQImage: isInFirstFold,
      };

      imagePlaceholderData.current = getPlaceholder({
        fittingType: displayMode,
        src: {
          id: uri,
          width: imageInfo.imageData.width,
          height: imageInfo.imageData.height,
          crop: imageInfo.imageData.crop,
          name: imageInfo.imageData.name,
          focalPoint: imageInfo.imageData.focalPoint,
        },
        target: {
          width: containerWidth,
          height: containerHeight,
          alignment: alignType,
          htmlTag: 'img',
        },
        options,
      });
    } else {
      // to keep an empty placeholder data
      imagePlaceholderData.current = {
        uri: undefined, // to remove src attribute completely
        css: { img: {} },
        attr: { img: {}, container: {} },
      };
    }
  }

  const placeholder = imagePlaceholderData.current;
  const src = placeholder?.uri;
  const srcset = placeholder?.srcset;
  const placeholderStyle = placeholder.css?.img;

  const image = (
    <img
      src={src}
      alt={alt}
      role={role}
      style={{ ...placeholderStyle, ...imageStyles }}
      onLoad={onLoad}
      onError={onError}
      {...(lazyLoadImgExperimentOpen
        ? isInFirstFold
          ? fetchPriorityExperimentOpen
            ? { fetchpriority: 'high' }
            : {}
          : { loading: 'lazy' }
        : {})}
      {...(isInFirstFold
        ? {
            srcSet: srcset?.dpr?.join(', '),
          }
        : {})}
    />
  );

  return isInFirstFold ? (
    <div
      id={fixedId}
      className={classNames(styles.image, className)}
      key={fixedId + isZoomed}
    >
      {image}
    </div>
  ) : (
    <wix-image
      id={fixedId}
      class={classNames(styles.image, className)}
      data-image-info={JSON.stringify(imageInfo)}
      data-has-bg-scroll-effect={hasBgScrollEffect}
      data-bg-effect-name={bgEffectName}
      data-image-zoomed={isZoomed || ''}
      data-has-ssr-src={hasSsrSrc}
      key={fixedId + isZoomed}
    >
      {image}
    </wix-image>
  );
};

export default Image;
